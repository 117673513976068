import type {AppProps} from 'next/app'
import {NextComponentType, NextPageContext} from "next";
import 'src/styles/tailwind.css'
import 'eyecons-components/dist/index.css'
import Head from "next/head";
import React from "react";
import Script from "next/script";

type CustomComponentType = NextComponentType<NextPageContext, any, any> & {
    getLayout?(page: React.ReactNode): React.ReactNode
}

export default function MyApp({Component, pageProps}: AppProps) {
    // Use the layout defined at the page level, if available
    const getLayout = (Component as CustomComponentType).getLayout || ((page) => page)

    return (
        <>
            <Head>
                <script src={`https://cdn.jwplayer.com/libraries/${process.env.NEXT_PUBLIC_JW_PLAYER_ID}.js`}/>
            </Head>

            {getLayout(<Component {...pageProps} />)}

            <Script src="https://mmcdn.nl/tags/eyecons.com/home.js" async/>
        </>
    )
}